import React, { useState, useMemo, useRef, useEffect } from 'react'

import Form from '../form/api-form'

import { navigate } from 'gatsby'

import Input from '../input'

import styles from './style.module.scss'

import Select from '../select-search'

import Button from '../button'

import QuestionImage from '../../images/question.svg'

import { transformList } from '../../helpers'

import {
    usePropertyLocations,
    usePropertyTypes,
    useSaveEnquiry,
    useEnquiryPupose,
} from '../hooks'

export default () => {
    const [open, setPoupOpen] = useState(false)

    const { response: locationsResponse } = usePropertyLocations()

    const locations = useMemo(() => transformList(locationsResponse), [
        locationsResponse,
    ])

    const { response: typesResponse } = usePropertyTypes()

    const types = useMemo(() => transformList(typesResponse), [typesResponse])

    const { response: purposeResponse } = useEnquiryPupose()

    const purposeList = useMemo(() => transformList(purposeResponse), [
        purposeResponse,
    ])

    const formRef = useRef({})

    const {
        statusCode,
        response: saveEnquiryResponse,
        loading,
        submit: saveEnquiry,
    } = useSaveEnquiry({
        formRef,
    })

    useEffect(() => {
        if (statusCode === 200) {
            setPoupOpen(true)
        }
    }, [statusCode])

    const onPopupClose = () => navigate('/')

    return (
        <div className={styles.postEnquiryForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Form
                            ref={formRef}
                            loading={loading}
                            statusCode={statusCode}
                            onSubmit={() => saveEnquiry()}
                            response={saveEnquiryResponse}
                            errorsClassName={styles.formErrors}
                        >
                            <h1>Post your Enquiry</h1>
                            <p>
                                We match your enquiry with listings submitted by
                                our community.
                            </p>
                            <p>
                                We are a Pleasant Environment for Property
                                Owners & Real Estate Professionals.
                            </p>
                            <Select
                                required
                                name="purpose"
                                label="Looking to"
                                options={purposeList}
                            />
                            <Select
                                required
                                name="type"
                                label="Type"
                                options={types}
                            />
                            <Select
                                required
                                name="location"
                                label="Location"
                                placeholder="Multiple locations can be selected"
                                options={locations}
                                isMulti
                            />

                            <Input required name="budget" label="Budget" />

                            <Input
                                required
                                name="number_of_rooms"
                                label="Number of bedrooms"
                                required
                            />

                            <Input
                                required
                                label="Message"
                                type="textarea"
                                name="message"
                            />

                            <Button className={styles.btn}>Submit</Button>
                        </Form>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <QuestionImage className={styles.question} />
                    </div>
                </div>
            </div>
        </div>
    )
}
