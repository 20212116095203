import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import PostEnquiryForm from '../components/post-enquiry-form'
import ProtectedPage from '../components/protected-page'
import { ACCOUNT_TYPE_TENANT_BUYER, ACCOUNT_TYPE_OWNER } from '../constants'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/',
                },
                {
                    title: 'Post enquiry',
                },
            ]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <ProtectedPage
                requiredAccountType={[
                    ACCOUNT_TYPE_TENANT_BUYER,
                    ACCOUNT_TYPE_OWNER,
                ]}
            >
                <PostEnquiryForm />
            </ProtectedPage>
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "post-enquiry" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
